import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Share",
    component: () => import("../views/share/index.vue"),
  },
  // 收益明细
  {
    path: "/earn-info",
    name: "EarnInfo",
    component: () => import("../views/earnInfo/index.vue"),
  },
  // 微代理
  {
    path: "/micro-proxy",
    name: "MicroProxy",
    component: () => import("../views/microProxy/index.vue"),
  },
  // 帮助中心
  {
    path: "/help-center",
    name: "helpCenter",
    component: () => import("../views/helpCenter/index.vue"),
  },
  // 去分享，邀请好友
  {
    path: "/invite-friends",
    name: "inviteFriends",
    component: () => import("../views/inviteFriends/index.vue"),
  },
  // 领取VIP
  {
    // path: '/receive-vip',
    path: "/qrcode",
    name: "receiveVip",
    component: () => import("../views/receiveVip/index.vue"),
  },
  // 注册会员
  {
    path: "/rego-vip",
    name: "regoVip",
    component: () => import("../views/regoVip/index.vue"),
  },
  // 提现
  {
    path: "/take-money",
    name: "takeMoney",
    component: () => import("../views/takeMoney/index.vue"),
  },
  {
    path: "/take-money-preview",
    name: "takeMoneyPreview",
    component: () => import("../views/takeMoney/preview.vue"),
  },
  {
    path: "/take-money-record",
    name: "takeMoneyRecord",
    component: () => import("../views/takeMoney/record.vue"),
  },
  {
    path: "/take-money-result",
    name: "takeMoneyResult",
    component: () => import("../views/takeMoney/result.vue"),
  },
  // 商店页面
  {
    path: "/shop",
    name: "shop",
    component: () => import("../views/shop/index.vue"),
  },
  // 下载页面
  {
    path: "/download",
    name: "download",
    component: () => import("../views/download/index.vue"),
  },

  // 邀请注册
  {
    path: "/reg-invite",
    component: () => import("../views/registerInvitation/index.vue"),
  },
  {
    path: "/reg-invite/auth",
    component: () => import("../views/registerInvitation/auth.vue"),
  },
  {
    path: "/reg-invite/register",
    component: () => import("../views/registerInvitation/register.vue"),
  },
  {
    path: "/reg-invite/disagree",
    component: () => import("../views/registerInvitation/disagree.vue"),
  },
  {
    path: "/reg-invite/disagreeResult",
    component: () => import("../views/registerInvitation/disagreeResult.vue"),
  },
  {
    path: "/reg-invite/payment",
    component: () => import("../views/registerInvitation/payment.vue"),
  },
  {
    path: "/reg-invite/result",
    component: () => import("../views/registerInvitation/result.vue"),
  },
  //调查问卷
  {
    path: "/inquire-into",
    name: "inquireInto",
    component: () => import("../views/inquireInto/index.vue"),
  },

  // 邀请注册V2
  {
    path: "/reg-invite-v2",
    component: () => import("../views/regInviteV2/index.vue"),
  },
  {
    path: "/reg-invite-v2/disagree",
    component: () => import("../views/regInviteV2/disagree.vue"),
  },
  {
    path: "/reg-invite-v2/register",
    component: () => import("../views/regInviteV2/register.vue"),
  },
  {
    path: "/reg-invite-v2/result",
    component: () => import("../views/regInviteV2/result.vue"),
  },

  // 邀请注册V3
  {
    path: "/reg-invite-v3",
    component: () => import("../views/regInviteV3/index.vue"),
  },
  {
    path: "/reg-invite-v3/register",
    component: () => import("../views/regInviteV3/register.vue"),
  },
  {
    path: "/reg-invite-v3/result",
    component: () => import("../views/regInviteV3/result.vue"),
  },
  {
    path: "/reg-invite-v3/disagree",
    component: () => import("../views/regInviteV3/disagree.vue"),
  },
  {
    path: "/reg-invite-v3/disagree-result",
    component: () => import("../views/regInviteV3/disagreeResult.vue"),
  },
  // 家长付费购买
  {
    path: "/parent-payment",
    component: () => import("../views/parentPayment/index.vue"),
  },
  {
    path: "/parent-payment/register",
    component: () => import("../views/parentPayment/register.vue"),
  },
  {
    path: "/parent-payment/device-order-enter",
    component: () => import("../views/parentPayment/device-order-enter.vue"),
  },
  {
    path: "/parent-payment/register-device-order",
    component: () => import("../views/parentPayment/register-device-order.vue"),
  },
  {
    path: "/parent-payment/enter",
    component: () => import("../views/parentPayment/primarySchool-device-order/enter.vue"),
  },
  {
    path: "/parent-payment/primarySchool-device-order",
    component: () => import("../views/parentPayment/primarySchool-device-order/index.vue"),
  },
  {
    path: "/parent-payment/juniorHighSchool-device-order/enter",
    component: () => import("../views/parentPayment/juniorHighSchool-device-order/enter.vue"),
  },
  {
    path: "/parent-payment/juniorHighSchool-device-order",
    component: () => import("../views/parentPayment/juniorHighSchool-device-order/index.vue"),
  },
  {
    path: "/parent-payment/result",
    component: () => import("../views/parentPayment/result.vue"),
  },

  // 收集学生信息
  {
    path: "/student-info",
    component: () => import("../views/studentInfo/index.vue"),
  },
  {
    path: "/student-info/register",
    component: () => import("../views/studentInfo/register.vue"),
  },
  {
    path: "/student-info/result",
    component: () => import("../views/studentInfo/result.vue"),
  },
  {
    path: "/student-info/disagree",
    component: () => import("../views/studentInfo/disagree.vue"),
  },
  {
    path: "/student-info/record",
    component: () => import("../views/studentInfo/record.vue"),
  },
  // 收集学生信息
  {
    path: "/student-info-hezhou",
    component: () => import("../views/studentInfoHezhou/index.vue"),
  },
  {
    path: "/student-info-hezhou/register",
    component: () => import("../views/studentInfoHezhou/register.vue"),
  },
  {
    path: "/student-info-hezhou/result",
    component: () => import("../views/studentInfoHezhou/result.vue"),
  },
  {
    path: "/student-info-hezhou/disagree",
    component: () => import("../views/studentInfoHezhou/disagree.vue"),
  },
  {
    path: "/student-info-hezhou/record",
    component: () => import("../views/studentInfoHezhou/record.vue"),
  },
  // 抽奖大转盘-首页
  {
    meta: {
      title: '成人达己',
    },
    path: "/lottery/index",
    component: () => import("../views/lottery/index.vue"),
  },
  // 抽奖大转盘-详细
  {
    meta: {
      title: '幸运大转盘',
    },
    path: "/lottery/detail",
    component: () => import("../views/lottery/detail.vue"),
  },
  // 抽奖大转盘-规则
  {
    meta: {
      title: '省钱攻略',
    },
    path: "/lottery/rule",
    component: () => import("../views/lottery/rule.vue"),
  },
  // 抽奖大转盘-分享
  {
    meta: {
      title: '分享',
    },
    path: "/lottery/share",
    component: () => import("../views/lottery/share.vue"),
  },
  // 抽奖大转盘-分享-详细
  {
    meta: {
      title: '分享详情',
    },
    name: "lottery-share-detail",
    path: "/lottery/shareDetail",
    component: () => import("../views/lottery/shareDetail.vue"),
  },
  // 积分明细
  {
    meta: {
      title: '积分明细',
    },
    name: "score-detail",
    path: "/lottery/scoreDetail",
    component: () => import("../views/lottery/scoreDetail.vue"),
  },
  // 数育币明细
  {
    meta: {
      title: '数育币明细',
    },
    name: "shuyu-coin-detail",
    path: "/lottery/shuyuCoinDetail",
    component: () => import("../views/lottery/shuyuCoinDetail.vue"),
  },
  // 分享酬金明细
  {
    meta: {
      title: '分享酬金明细',
    },
    name: "share-remuneration-details",
    path: "/lottery/shareRemunerationDetails",
    component: () => import("../views/lottery/shareRemunerationDetails/index.vue"),
  },
  {
    meta: {
      title: '跳转评论页',
    },
    name: "toCommentPage",
    path: "/toCommentPage",
    component: () => import("../views/lottery/toCommentPage.vue"),
  },
  {
    meta: {
      title: '分享教程',
    },
    name: "shareTutorial",
    path: "/shareTutorial",
    component: () => import("../views/lottery/shareTutorial.vue"),
  },
  {
    meta: {
      title: '验证发布结果',
    },
    name: "verificationContentShare",
    path: "/verificationContentShare",
    component: () => import("../views/lottery/verificationContentShare.vue"),
  },
  {
    meta: {
      title: '内容分享',
    },
    name: "contentShare",
    path: "/contentShare",
    component: () => import("../views/lottery/contentShare.vue"),
  },
  {
    meta: {
      title: '数育帮',
    },
    name: "register",
    path: "/register",
    component: () => import("../views/register/index.vue"),
  },
  {
    meta: {
      title: '数育帮',
    },
    name: "registerByChannel",
    path: "/registerByChannel",
    component: () => import("../views/registerByChannel/index.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
